var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    {
      staticClass:
        "vh-100 d-flex  align-items-center justify-content-center all-container",
      attrs: { fluid: "" }
    },
    [
      _c(
        "CRow",
        { staticClass: "w-50", staticStyle: { "max-width": "650px" } },
        [
          _c(
            "CCol",
            [
              _c(
                "CCard",
                { staticClass: "mx-4 mb-0" },
                [
                  _c("CCardBody", { staticClass: "p-5" }, [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("div", { staticClass: "text-center py-3" }, [
                          _c("img", { attrs: { src: "img/OxyConnect.png" } })
                        ]),
                        _c(
                          "CForm",
                          { staticClass: "p-2 my-2" },
                          [
                            _c("h3", { staticClass: "mt-2" }, [
                              _vm._v("パスワード再設定")
                            ]),
                            _c("CInput", {
                              staticClass: "mt-2",
                              attrs: {
                                autocomplete: "new-password",
                                value: _vm.$route.params.loginId,
                                readonly: ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "prepend-content",
                                  fn: function() {
                                    return [_vm._v("ログインID")]
                                  },
                                  proxy: true
                                }
                              ])
                            }),
                            _c("CInput", {
                              attrs: {
                                placeholder:
                                  "新しいパスワードを入力してください。",
                                type: "password",
                                autocomplete: "new-password"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "prepend-content",
                                  fn: function() {
                                    return [
                                      _c("CIcon", {
                                        attrs: { name: "cil-lock-locked" }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ]),
                              model: {
                                value: _vm.password,
                                callback: function($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password"
                              }
                            }),
                            _c("CInput", {
                              staticClass: "mb-4",
                              attrs: {
                                placeholder:
                                  "確認のため、もう一度新しいパスワードを入力してください。",
                                type: "password",
                                autocomplete: "new-password"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "prepend-content",
                                  fn: function() {
                                    return [
                                      _c("CIcon", {
                                        attrs: { name: "cil-lock-locked" }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ]),
                              model: {
                                value: _vm.passwordConfirm,
                                callback: function($$v) {
                                  _vm.passwordConfirm = $$v
                                },
                                expression: "passwordConfirm"
                              }
                            }),
                            _c(
                              "CButton",
                              {
                                attrs: { color: "success", block: "" },
                                on: { click: _vm.passwordResetMail }
                              },
                              [_vm._v("パスワード再設定")]
                            ),
                            _c(
                              "CButton",
                              {
                                attrs: { color: "success", block: "" },
                                on: { click: _vm.toLogin }
                              },
                              [_vm._v("ログイン画面へ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }