<template>
  <CContainer fluid class="vh-100 d-flex  align-items-center justify-content-center all-container" >
    <CRow class="w-50" style="max-width: 650px;" >
      <CCol>
        <CCard class="mx-4 mb-0">
          <CCardBody class="p-5">
            <div class="text-center">
              <div class="text-center py-3">
                <img src="img/OxyConnect.png"/>
              </div>
              <CForm class="p-2 my-2">
                <h3 class="mt-2">パスワード再設定</h3>
                <CInput class="mt-2"
                    autocomplete="new-password"
                    :value="$route.params.loginId" readonly>
                  <template #prepend-content>ログインID</template>
                </CInput>

                <CInput
                  placeholder="新しいパスワードを入力してください。"
                  type="password"
                  autocomplete="new-password"
                  v-model="password">
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CInput
                  placeholder="確認のため、もう一度新しいパスワードを入力してください。"
                  type="password"
                  autocomplete="new-password"
                  class="mb-4"
                  v-model="passwordConfirm">
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <CButton color="success" block @click="passwordResetMail">パスワード再設定</CButton>
                <CButton color="success" block @click="toLogin">ログイン画面へ</CButton>
              </CForm>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import axios from "axios";

export default {
  name: 'Register',
  data(){
    return {
      password:"",
      passwordConfirm:""
    }
  },
  methods:{
    passwordResetMail(){
      if(this.password != this.passwordConfirm){
        this.$swal("新規のパスワードと確認用新規のパスワードが一致しません。");
        return;
      }
      if (this.password == "" || this.password.length < 8) {
        this.$swal("パスワードは8桁以上32桁以下の半角英数字・記号を入力してください。");
        return;
      }

      this.axios.post('/api/user/password/reset',
              {
                token:this.$route.params.token,
                password:this.password
              }
              , {
                headers: {'Content-Type': 'application/json'}
              }
      ).then(response => {
        console.log('response', response);
        this.$router.replace("/login");
      }).catch(error => {
        console.log('error', error);
        console.log('error', error.response.data);
      }).finally(() => {
        console.log('finally');
      });
    },toLogin:function(){
      this.$router.replace({ path: "/" });
    }
  }

}
</script>
<style type="text/css" scoped>
.loginBadge{
  cursor:pointer;
}
.all-container{
  background-color: #F0F0F0;
}
.card,.card-body{
  background-color: #FFFFFF;
}
.card-body {
  border: solid 1px #969191;
}
a {
  color:black;
  font-size: 1rem;
}

.explan{
  color:black;
  font-size: 1rem;
}
.custom-file-label::after{
  content: "ファイルを選択";
}
/*[src$=png]{*/
/*  margin-top: 30px;*/
/*}*/
</style>